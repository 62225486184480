.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Cat{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

button{
  background-color: slategrey;
  height:40px ;
  font-size: 1.5rem;
  border-radius: 5%;
  }

  img{
    width: 450px;
    height: 500px;
    margin: 20px;
    
    border-radius: 10%;
    border: 2px solid brown;
   
   
  }

